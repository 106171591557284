import * as React from "react";
import * as styles from "./account-details.module.css";

import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { FunctionComponent, useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import useAuth, { User } from "../useAuth";

import BillingDetails from "./billing-details";
import FormInput from "../Register/FormInput";
import { GET_CUSTOMER } from "../../queries/getCustomer";
import { PREFERRED_COMMUNICATION } from "../../constants/ preferredCommunication";
import PasswordCard from "./PasswordCard";
import ProfessionSelection from "../ProfessionSelection";
import UPDATE_PROFILE from "../../queries/updateProfile";
import { getAllCountries } from "../hooks/getAllCountries";
import { usePreferredCommunication } from "../../hooks/usePreferredCommunication";
import { useProfessionSelection } from "../../hooks/professionSelection";

export default function AccountDetails() {
  var regionNames = new Intl.DisplayNames(["EN"], { type: "region" });

  const toast = useToast();
  var allCountries = getAllCountries();

  const { user } = useAuth();
  const { id, databaseId, firstName, lastName, email } = user as User;

  const [updateProfile, { data, loading, error }] = useMutation(
    UPDATE_PROFILE,
    {
      onCompleted: () => {
        toast({
          title: "Your profile was successfully updated!",
          status: "success",
        });
        // window.location.reload();
      },
      onError: () => {
        toast({
          title: "Error",
          description: "There was an error updating you profile.",
          status: "error",
        });
      },
    }
  );

  const customer = useQuery(GET_CUSTOMER, {
    variables: { id: user?.id },
  });

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const values: any = {};
    formData.forEach((value, key) => {
      values[key] = value;
    });

    updateProfile({
      variables: {
        id,
        ...values,
      },
    }).catch((error) => {
      console.error(error);
    });
  }

  const [selects, setSelects] = useState("");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [licenseNumberError, setLicenseNumberError] = useState("");
  const [licenseExpiryDateError, setLicenseExpiryDateError] = useState("");

  const customerDetails = customer.data?.customer;

  const {
    preferredCommunication,
    setPreferredCommunication,
    otherPreferredCommunication,
    setOtherPreferredCommunication,
    selectedPreferredCommunicationValue,
  } = usePreferredCommunication(
    customer.data?.customer?.preferredCommunication
  );

  const {
    medicalProfessionalName,
    setMedicalProfessionalName,
    otherMedicalProfessionalName,
    setOtherMedicalProfessionalName,
    selectedMedicalProfessionalNameValue,
    medicalProfessionalNameItems,
    medicalProfessionalNameError,
    setMedicalProfessionalNameError,
    profession,
    setProfession,
    otherProfession,
    setOtherProfession,
    selectedProfessionValue,
    professionItems,
    professionError,
    setProfessionError,
    isProfessionRequired,
    specialty,
    setSpecialty,
    otherSpecialty,
    setOtherSpecialty,
    selectedSpecialtyValue,
    specialtyItems,
    specialtyError,
    setSpecialtyError,
    isSpecialtyRequired,
  } = useProfessionSelection({
    initialMedicalProfessionalNameValue:
      customer.data?.customer?.medicalProfessionalName,
    initialProfessionValue: customer.data?.customer?.profession,
    initialSpecialtyValue: customer.data?.customer?.specialty,
  });

  const [updatedDetails, setUpdatedDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    phone: "",
    fax: "",
    password: "",
    storeCredit: "",
    loyaltyPoints: "",
    medicalProfessionalName: "",
    postcode: "",
    profession: "",
    specialty: "",
    medicalLicenceNumber: "",
    licenceExpirationDate: "",
    preferredCommunication: "",
    additionalPhoneNumber1: "",
  });

  useEffect(() => {
    if (customerDetails) {
      setUpdatedDetails({
        firstName: customer.data?.customer.firstName,
        lastName: customer.data?.customer.lastName,
        email: customer.data?.customer.email,
        phoneNumber: customer.data?.customer.phoneNumber,
        phone: customer.data?.customer.phone,
        fax: customer.data?.customer.fax,
        password: "",
        storeCredit: customer.data?.customer.storeCredit,
        loyaltyPoints: customer.data?.customer.loyaltyPoints,
        medicalProfessionalName:
          customer.data?.customer.medicalProfessionalName,
        profession: customer.data?.customer.profession,
        specialty: customer.data?.customer.specialty,
        postcode: customer.data?.customer.billing.postcode,
        medicalLicenceNumber: customer.data?.customer.medicalLicenceNumber,
        licenceExpirationDate: customer.data?.customer.licenceExpirationDate,
        preferredCommunication: customer.data?.customer.preferredCommunication,
        additionalPhoneNumber1: customer.data?.customer.additionalPhoneNumber1
      });
    }
  }, [customerDetails]);


  useEffect(() => {
    changeUpdatedDetails({
      medicalProfessionalName: selectedMedicalProfessionalNameValue,
      profession: "",
      specialty: "",
    });
  }, [selectedMedicalProfessionalNameValue]);

  useEffect(() => {
    changeUpdatedDetails({
      profession: selectedProfessionValue,
      specialty: "",
    });
  }, [selectedProfessionValue]);

  useEffect(() => {
    changeUpdatedDetails({
      specialty: selectedSpecialtyValue,
    });
  }, [selectedSpecialtyValue]);

  const changeUpdatedDetails = (newState: any) =>
    setUpdatedDetails((prevState: any) => ({ ...prevState, ...newState }));

  useEffect(() => {
    changeUpdatedDetails({
      preferredCommunication: selectedPreferredCommunicationValue,
    });
  }, [selectedPreferredCommunicationValue]);

  return (
    <>
      <VStack w="100%" alignItems="flex-start" pl={{ base: 0, md: "24px" }} py="12px">
        <Text as="span" fontSize="lg" mb="12px" fontWeight="600">
          Total Loyalty Points:{" "}
          <Text as="span" color="#D28C49">
            {customer.data?.customer.loyaltyPoints}
          </Text>
        </Text>
        <Text as="span" fontSize="lg" mb="12px" fontWeight="600">
          Store Credit:{" "}
          <Text as="span" color="#D28C49">
            ${customer.data?.customer.storeCredit}
          </Text>
        </Text>
      </VStack>
      <div className={styles.settingsPanel}>
        <div className={styles.shoppingCartLayout}>
          <form
            method="post"
            onSubmit={handleSubmit}
            className={styles.billingDetails}
          >
            <Text as="h2" fontWeight={700} className={styles.billingDetails1}>
              Account details
            </Text>
            <div className={styles.personalDetails}>
              <div className={styles.cta}>
                <div className={styles.fullName}>
                  <div className={styles.inputTextField}>
                    <div className={styles.label}>
                      <Text
                        fontWeight={700}
                        className={styles.fullNameContainer}
                      >
                        <span>{`Full name `}</span>
                        <span className={styles.span}>*</span>
                      </Text>
                    </div>
                    <input
                      className={styles.inputFirstname}
                      type="text"
                      required
                      defaultValue={firstName}
                      placeholder={firstName}
                      name="firstName"
                    />
                    <div className={styles.helperMessage}>
                      <Text className={styles.helperMessage1}>
                        Helper message
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.address}>
                <div className={styles.inputNumberField}>
                  <div className={styles.inputTextField2}>
                    <div className={styles.label}>
                      <Text
                        fontWeight={700}
                        className={styles.fullNameContainer}
                      >
                        <span>{`Address line 1 `}</span>
                        <span className={styles.span}>*</span>
                      </Text>
                    </div>
                    <input
                      className={styles.inputFirstname}
                      type="text"
                      required
                      defaultValue={
                        customer.data?.customer.billing.address1 || ""
                      }
                      placeholder={
                        customer.data?.customer.billing.address1 || ""
                      }
                      name="address1"
                    />
                    <div className={styles.helperMessage}>
                      <Text className={styles.helperMessage1}>
                        Helper message
                      </Text>
                    </div>
                  </div>
                </div>
                <div className={styles.inputSelect}>
                  <div className={styles.label}>
                    <Text fontWeight={700} className={styles.fullNameContainer}>
                      <span>{`Address line 2 `}</span>
                    </Text>
                  </div>
                  <input
                    className={styles.inputFirstname}
                    type="text"
                    defaultValue={
                      customer.data?.customer.billing.address2 || ""
                    }
                    placeholder={customer.data?.customer.billing.address2 || ""}
                    name="address2"
                  />
                  <div className={styles.helperMessage}>
                    <Text className={styles.helperMessage1}>
                      Helper message
                    </Text>
                  </div>
                </div>
                <div className={styles.inputNumberField}>
                  <div className={styles.inputTextField2}>
                    <div className={styles.label}>
                      <Text
                        fontWeight={700}
                        className={styles.fullNameContainer}
                      >
                        Unit / Suite / Apartment (optional)
                      </Text>
                    </div>
                    <input className={styles.inputFirstname} type="text" />
                    <div className={styles.helperMessage}>
                      <Text className={styles.helperMessage1}>
                        Helper message
                      </Text>
                    </div>
                  </div>
                </div>
                <div className={styles.inputSelect}>
                  <div className={styles.label}>
                    <Text className={styles.fullNameContainer}>
                      <span>{`Country / Region `}</span>
                      <span className={styles.span}>*</span>
                    </Text>
                  </div>
                  <Select
                    variant="onstyled"
                    className={styles.inputCountry}
                    id="country1"
                    name="country1"
                  >
                    <option>
                      {customer.data?.customer.billing.country || ""}
                    </option>
                    {allCountries.map((item: any) => {
                      return (
                        <option value={item.countryCode}>
                          {regionNames.of(item.countryCode)}
                        </option>
                      );
                    })}
                  </Select>
                  <div className={styles.helperMessage}>
                    <Text className={styles.helperMessage1}>
                      Helper message
                    </Text>
                  </div>
                </div>
                <div className={styles.inputNumberField}>
                  <div className={styles.inputTextField2}>
                    <div className={styles.label}>
                      <Text
                        fontWeight={700}
                        className={styles.fullNameContainer}
                      >
                        <span>{`Town / City `}</span>
                        <span className={styles.span}>*</span>
                      </Text>
                    </div>
                    <input
                      className={styles.inputFirstname}
                      type="text"
                      required
                      defaultValue={customer.data?.customer.billing.city || ""}
                      placeholder={customer.data?.customer.billing.city || ""}
                      name="city"
                    />
                    <div className={styles.helperMessage}>
                      <Text className={styles.helperMessage1}>
                        Helper message
                      </Text>
                    </div>
                  </div>
                </div>
                <div className={styles.inputNumberField}>
                  <div className={styles.inputTextField2}>
                    <div className={styles.label}>
                      <Text
                        fontWeight={700}
                        className={styles.fullNameContainer}
                      >
                        <span>{`State `}</span>
                        <span className={styles.span}>*</span>
                      </Text>
                    </div>
                    <input
                      className={styles.inputFirstname}
                      type="text"
                      required
                      defaultValue={customer.data?.customer.billing.state || ""}
                      placeholder={customer.data?.customer.billing.state || ""}
                      name="state"
                    />
                    <div className={styles.helperMessage}>
                      <Text className={styles.helperMessage1}>
                        Helper message
                      </Text>
                    </div>
                  </div>
                </div>
                <div className={styles.inputNumberField}>
                  <div className={styles.inputTextField2}>
                    <div className={styles.label}>
                      <Text
                        fontWeight={700}
                        className={styles.fullNameContainer}
                      >
                        <span>{`Postcode / ZIP `}</span>
                        <span className={styles.span}>*</span>
                      </Text>
                    </div>
                    <input
                      className={styles.inputFirstname}
                      type="number"
                      required
                      value={customer.data?.customer.billing.postcode}
                      defaultValue={
                        customer.data?.customer.billing.postcode || ""
                      }
                      placeholder={
                        customer.data?.customer.billing.postcode || ""
                      }
                      name="postcode"
                    />
                    <div className={styles.helperMessage}>
                      <Text className={styles.helperMessage1}>
                        Helper message
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.separator} />
              <div className={styles.address}>
                <div className={styles.inputNumberField}>
                  <div className={styles.inputTextField2}>
                    <div className={styles.label}>
                      <Text
                        fontWeight={700}
                        className={styles.fullNameContainer}
                      >
                        <span>{`Phone `}</span>
                        <span className={styles.span}>*</span>
                      </Text>
                    </div>
                    <input
                      className={styles.inputFirstname}
                      type="number"
                      required
                      defaultValue={customer.data?.customer.phoneNumber || ""}
                      placeholder={customer.data?.customer.phoneNumber || ""}
                      name="phoneNumber"
                    />

                    <FormControl pt="8px">
                      <FormLabel
                        fontWeight="700"
                        fontSize="14px">Additional phone number
                      </FormLabel>
                      <Input
                        className={styles.inputFirstname}
                        type="number"
                        required
                        defaultValue={customer.data?.customer.additionalPhoneNumber1 || ""}
                        placeholder={customer.data?.customer.additionalPhoneNumber1 || ""}
                        name="additionalPhoneNumber1"
                      />
                    </FormControl>
                    <div className={styles.helperMessage}>
                      <Text className={styles.helperMessage1}>
                        Helper message
                      </Text>
                    </div>
                  </div>
                </div>
                <div className={styles.inputNumberField}>
                  <div className={styles.inputTextField2}>
                    <div className={styles.label}>
                      <Text
                        fontWeight={700}
                        className={styles.fullNameContainer}
                      >
                        <span>{`Email address `}</span>
                        <span className={styles.span}>*</span>
                      </Text>
                    </div>
                    <input
                      className={styles.inputFirstname}
                      type="email"
                      required
                      defaultValue={email || ""}
                      placeholder={email || ""}
                      name="email"
                    />
                    <div className={styles.helperMessage}>
                      <Text className={styles.helperMessage1}>
                        Helper message
                      </Text>
                    </div>
                  </div>
                </div>
                <FormControl >
                  <FormLabel fontSize='14px' fontWeight="700">Preferred Communication</FormLabel>
                  <FormControl
                    cursor="pointer"
                    border="1px solid #d9d9d9"
                    borderRadius="5px"
                  >
                    <Select
                      // @ts-ignore
                      selectedOptionColorScheme="black"
                      h="40px"
                      pl={"10px"}
                      maxMenuHeight={130}
                      variant="unstyled"
                      id="preferredCommunication"
                      name="preferredCommunication"
                      placeholder={
                        customer.data?.customer.preferredCommunication
                      }
                      defaultValue={
                        customer.data?.customer.preferredCommunication
                      }
                      value={selectedPreferredCommunicationValue}
                      onChange={(e) => {
                        if (e) {
                          // @ts-ignore
                          setPreferredCommunication(e);
                        }
                      }}
                    >
                      {PREFERRED_COMMUNICATION.map((option) => (
                        <option
                          style={{ marginLeft: "10px" }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  {preferredCommunication?.value === "Others" && (
                    <FormInput
                      height="40px"
                      value={otherPreferredCommunication}
                      setValue={setOtherPreferredCommunication}
                      placeholder="Enter preferred communication"
                    />
                  )}
                </FormControl>
                <ProfessionSelection
                  medicalProfessionalName={medicalProfessionalName}
                  setMedicalProfessionalName={setMedicalProfessionalName}
                  medicalProfessionalNameError={medicalProfessionalNameError}
                  profession={profession}
                  setProfession={setProfession}
                  specialty={specialty}
                  setSpecialty={setSpecialty}
                  otherMedicalProfessionalName={otherMedicalProfessionalName}
                  setOtherMedicalProfessionalName={
                    setOtherMedicalProfessionalName
                  }
                  otherProfession={otherProfession}
                  setOtherProfession={setOtherProfession}
                  otherSpecialty={otherSpecialty}
                  setOtherSpecialty={setOtherSpecialty}
                  medicalProfessionalNameItems={medicalProfessionalNameItems}
                  professionItems={professionItems ?? []}
                  specialtyItems={specialtyItems ?? []}
                  isProfessionRequired={isProfessionRequired}
                  isSpecialtyRequired={isSpecialtyRequired}
                  professionError={professionError}
                  specialtyError={specialtyError}
                  fontWeight="700"
                />

                <Flex
                  flexDirection={{ base: "column", sm: "row" }}
                  gap={{ base: 0, sm: 4 }}
                  alignItems="flex-start"
                  width="full"
                >
                  <FormControl
                    mb={{ base: "0px", md: "24px" }}
                    isRequired
                    mt="12px"
                  >
                    <FormLabel
                      fontSize='14px' fontWeight="700"
                      className={styles.fullNameContainer}
                    >
                      Medical license number
                    </FormLabel>
                    <Input
                      height="40px"
                      defaultValue={
                        customer.data?.customer?.medicalLicenceNumber
                      }
                      placeholder={
                        customer.data?.customer?.medicalLicenceNumber
                      }
                      onChange={(e) =>
                        changeUpdatedDetails({
                          medicalLicenceNumber: e.target.value,
                        })
                      }
                    />
                    <FormErrorMessage>{licenseNumberError}</FormErrorMessage>
                  </FormControl>

                  <FormControl mt="12px" isRequired>
                    <FormLabel
                      fontSize='14px' fontWeight="700"
                      className={styles.fullNameContainer}
                    >
                      License expiry date
                    </FormLabel>
                    <Input
                      height="40px"
                      type="date"
                      value={
                        customer.data?.customer.licenceExpirationDate
                          ? new Date(
                            customer.data?.customer.licenceExpirationDate
                          )
                            .toISOString()
                            .substring(0, 10)
                          : undefined
                      }
                      placeholder={
                        customer.data?.customer.licenceExpirationDate
                          ? new Date(
                            customer.data?.customer.licenceExpirationDate
                          )
                            .toISOString()
                            .substring(0, 10)
                          : undefined
                      }
                      defaultValue={
                        customer.data?.customer.licenceExpirationDate
                          ? new Date(
                            customer.data?.customer.licenceExpirationDate
                          )
                            .toISOString()
                            .substring(0, 10)
                          : undefined
                      }
                      onChange={(e) => {
                        changeUpdatedDetails({
                          licenceExpirationDate: e.target.value,
                        });
                      }}
                    />
                    <FormErrorMessage>
                      {customer.data?.customer.licenseExpiryDateError}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
              </div>
            </div>
            <div className={styles.cta}>
              <div className={styles.cta1}>
                <button type="submit" className={styles.darkButton}>
                  <b className={styles.label10}>
                    {loading ? "SAVING..." : "SAVE"}
                  </b>
                </button>
              </div>
            </div>
          </form>
          <PasswordCard />
        </div>
        <div className={styles.summarySlot}>
          <BillingDetails />
        </div>
      </div>
    </>
  );
}
