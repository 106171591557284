import * as styles from "../../pages/account-settings.module.css";

import { Button, Link, HStack } from "@chakra-ui/react";
import LogOut from "../Logout/LogOut";
import React from 'react'

export default function OrderHistoryButton() {
    return (
        <HStack w="100%" >
            <Link href="https://track.doctormedica.co/" target="_blank">
                <Button variant="outline" textTransform="uppercase">Order Tracking</Button>
            </Link>
            <Link href="/order-history" _hover={{color: "white", textDecoration: "none"}}>
                <Button variant="outline" textTransform="uppercase">
                    <b>Shopping History</b>
                </Button>
            </Link>
            <LogOut />
        </HStack>
  )
}
